<template>
  <div class="view pa24">
    <div style="font-size: 26px;font-weight: bold;margin-bottom: 30px;">API信息</div>
    <div class="d-flex">
      <div class="w50p">
        <div class="fs10 mb50">代收对接资料</div>
        <div class="mb10 fs9">
          <span class="c51">商户号：</span>
          <span>{{ mch_id.mch_id }}</span>
        </div>
        <div class="mb10 fs9 ">
          <span class="c51">代收下单地址：</span>
          <span>{{ daishou.submit_url }}</span>
        </div>
        <div class="mb10 fs9">
          <span class="c51">代收订单查询：</span>
          <span>{{ daishou.query_url }}</span>
        </div>
        <div class="mb10 fs9">
          <span class="c51">代收余额查询：</span>
          <span>{{ daishou.query_balance }}</span>
        </div>
        <!-- <div class="mb10 fs9">
        <span class="c51">网关地址：</span>
        <span></span>
      </div> -->
        <!-- <div class="mb10 fs9">
          <span class="ffa">秘钥：</span>
          <span class="c51 hover_pointer" @click="toView">查看</span>
        </div> -->
      </div>
      <div class="w50p">
        <div class="fs10 mb50">代付对接资料</div>
        <div class="mb10 fs9">
          <span class="c51">商户号：</span>
          <span>{{ mch_id.mch_id }}</span>
        </div>
        <div class="mb10 fs9">
          <span class="c51">代付下单地址：</span>
          <span>{{ daifu.submit_url }}</span>
        </div>
        <div class="mb10 fs9">
          <span class="c51">代付订单查询：</span>
          <span>{{ daifu.query_url }}</span>
        </div>
        <div class="mb10 fs9">
          <span class="c51">代付余额查询：</span>
          <span>{{ daifu.query_balance }}</span>
        </div>
        <div class="mb10 fs9">
          <span class="c51" style="cursor: pointer" @click="toPage(daifu.document_url)">点击查看api代付文档</span>
        </div>
        <!-- <div class="mb10 fs9">
        <span class="c51">网关地址：</span>
        <span>{{daifu.submit_url}}</span>
      </div> -->
        <div class="mb10 fs9">
          <span class="ffa">秘钥：{{ miyao }}</span>
          <span v-if="!miyao" class="c51 hover_pointer" @click="toView">查看</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex';
import {
  apiinfo,
  getKey
} from "@/api/settlement";

let ac;
export default {
  name: 'windowSet',
  data() {
    return {
      mch_id: {},
      daifu: {},
      daishou: {},
      miyao: ''
    };
  },
  mounted() {
    this.getCGSList()
  },

  methods: {
    toPage(url){
      window.open(url)
    },
    async getCGSList() {
      let ajax = {}
      apiinfo(ajax).then((res) => {
        if (res.code !== 1) {
          return;
        }
        this.daifu = res.data.daifu
        this.daishou = res.data.daishou
        this.mch_id = res.data.mch_id
      })
    },
    toView() {
      this.$prompt('请输入谷歌验证码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({
                 value
               }) => {
        if (!value) {
          this.$message.warning('请输入谷歌验证码')
          return
        } else {
          let ajax = {google: value}
          getKey(ajax).then((res) => {
            if (res.code !== 1) {
              this.$message.error(res.msg)
              return;
            }
            this.miyao = res.data.key
          }).catch(err => {
            this.$message.error(err.msg)
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>